<template>
  <div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
      items: [
        { _id: 1, name: "លុយដុល្លា - Usd" },
        { _id: 2, name: "លុយរៀល - Khmer" },
        { _id: 3, name: "លុយបាត - Baht" },
      ],
      type_id: 1,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    selectCurrencyType(type_id) {
      this.params.currency_type = type_id;
      this.fetchGetAllPayOut(this.params);
    },
    async deleteItemConfirm() {
      await this.deleteData({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      this.fetchGetAllPayOut(this.params);
      this.dialogDelete = false;
    },

    async onCreateDialog() {
      this.fetchPopUp(true);
    },
    ...mapActions("$_payOut", [
      "fetchGetAllPayOut",
      "deleteData",
      "fetchPopUp",
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>
